import {
  DocumentUploadIn,
  ChangeDocumentTypeIn,
} from '@repo/common/dtos';
import axios from 'axios';

class Document {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/document`;
  }

  async createWithApplication(applicationId: string, document: DocumentUploadIn): Promise<string> {
    const response = await axios.put<string>(`${this.baseUrl}`, document, {
      params: {
        application_id: applicationId,
      },
    });

    return response.data;
  }

  async createWithCompany(companyId: string, document: DocumentUploadIn): Promise<string> {
    const response = await axios.put<string>(`${this.baseUrl}`, document, {
      params: {
        company_id: companyId,
      },
    });

    return response.data;
  }

  async update(documentId: string, applicationId: string, documentInfo: ChangeDocumentTypeIn, undo = false): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/${documentId}?application_id=${applicationId}&undo=${undo}`, documentInfo);

    return response.data;
  }
}

export default Document;
